<template>
    <div class="home">
        <h1>Welcome to My Homepage</h1>
        <div class="description">
            <h2>About Me</h2>
            <p>{{ description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            description: "I am Emilian Popp. I am a software developer with a passion for creating innovative solutions.",
        };
    },
};
</script>

<style scoped>
.home {
    text-align: center;
}

.description {
    margin-top: 20px;
}
</style>
